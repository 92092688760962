<template>
  <g>
    <svg:style>
      .cls-2, .cls-3, .cls-4, {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }

      .cls-2 {
        fill: url(#inox-gradient-2);
      }

      .cls-3 {
        fill: url(#inox-gradient-3);
      }

      .cls-4 {
        fill: url(#inox-gradient-3);
      }
    </svg:style>
    <linearGradient id="inox-gradient"
      x1="93.19"
      y1="279.3"
      x2="93.19"
      y2="22.23"
      gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stop-color="#babbbc"/>
      <stop offset="0.29" stop-color="#989a9e"/>
      <stop offset="0.65" stop-color="#bdbdc1"/>
      <stop offset="0.87" stop-color="#9d9fa2"/>
      <stop offset="1" stop-color="#a6a8aa"/>
      <stop offset="1" stop-color="#c2c5c9"/>
    </linearGradient>
    <linearGradient id="inox-gradient-2" data-name="inox"
      :x1="inox1X"
      :y1="0"
      :x2="inox1X + inoxWidth"
      :y2="inox1Y + doorHeight"
      xlink:href="#inox-gradient"/>
    <linearGradient id="inox-gradient-3" data-name="inox"
      :x1="inox1X"
      :y1="0"
      :x2="inox1X + inoxWidth"
      :y2="inox1Y + doorHeight"
      xlink:href="#inox-gradient"/>
    <linearGradient id="handle-gradient"
      :x1="inox1X"
      :y1="0"
      :x2="inox1X + inoxWidth"
      :y2="inox1Y + doorHeight"
      xlink:href="#inox-gradient"/>
    <filter id="inset-shadow">
      <feOffset dx="0" dy="0"/>
      <feGaussianBlur stdDeviation="1"  result="offset-blur"/>
      <feComposite operator="out" in="SourceGraphic" in2="offset-blur" result="inverse"/>
      <feFlood flood-color="black" flood-opacity="1" result="color"/>
      <feComposite operator="in" in="color" in2="inverse" result="shadow"/>
      <feComponentTransfer in="shadow" result="shadow">
        <feFuncA type="linear" slope="1.25"/>
      </feComponentTransfer>
      <feComposite operator="over" in="shadow" in2="SourceGraphic"/>
    </filter>
    <g id="L07">
      <rect id="inox"
            v-if="showInox"
            data-name="inox"
            class="cls-2"
            :x="inox1X"
            :y="inox1Y"
            width="2.13"
            :height="inoxHeight"/>
      <rect id="inox-2"
            v-if="showInox"
            data-name="inox"
            class="cls-3"
            :x="inox2X"
            :y="inox1Y"
            width="2.13"
            :height="inoxHeight"/>
      <rect id="inox-3"
            v-if="showInox"
            data-name="inox"
            class="cls-2"
            :x="inox3X"
            :y="inox1Y"
            width="2.13"
            :height="inoxHeight"/>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
    'showBg',
  ],
  data() {
    return {
      inoxOffset: 22.23,
      inox2Left: 22,
      leaf2Left: 7,
    }
  },
  computed: {
    inox1X() {
      return this.doorLeftWidth + this.doorWidth * .6
    },
    inox2X() {
      return this.doorLeftWidth + this.doorWidth * .65
    },
    inox3X() {
      return this.doorLeftWidth + this.doorWidth * .7
    },
    inox1Y() {
      return this.showBg ?
        this.doorTopHeight + this.inoxOffset :
        this.doorTopHeight + this.leaf2Left
    },
    inoxHeight() {
      return this.showBg ?
        this.doorHeight - 2 * this.inoxOffset :
        this.doorHeight - 2 * this.leaf2Left
    },
  },
}
</script>
